<template>
    <div class="input-select-component">
        <el-input style="width: 100%;"
            ref="refInput"
            v-model="inpValue"
            :placeholder="placeholder"
            :disabled="disabled"
            :clearable="clearable"
            @clear="clear"
            @blur="blur"
            @focus="focus"
            @input="input">
            <template #prepend v-if="selectList.length">
            <el-select v-model="selectVal" style="width: 80px" @change="changeSelect">
                <el-option v-for="(item, index) in selectList" :key="index" :label="item.label" :value="item.value"></el-option>
            </el-select>
        </template>
        </el-input>
        <div :class="['input-select-component-box', float ===  'right' ? 'input-select-component-box-right' : 'input-select-component-box-left' ]" v-show="showTable">
            <el-table style="width: 100%;"
                :data="tableData"
                @row-click="rowClick"
                v-loading="loading"
                >
                <el-table-column
                    v-for="(item,index) in columns" :key="index"
                    :width="item.width ? item.width : '150px'"
                    :prop="item.prop"
                    :label="item.label">
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import http from '@/utils/http';

export default {
    props: {
        values: {
            type: String,
            required: true
        },
        value: {
            type: String,
            default: '',
            required: true
        },
        advanceCascade: { // 接口查询字段
            type: String,
            required: false
        },
        columns: { // 表格配置项
            type: Array,
            required: true
        },
        selectList: {
            type: Array,
            default() {
                return [];
            }
        },
        prefix: { // 接口前缀
            type: String,
            default: '/api-auth'
        },
        url: { // 接口地址
            type: String,
            required: true
        },
        method: { // 请求方式
            type: String,
            default: 'get'
        },
        queryKeys: { // 接口查询字段
            type: Array,
            required: true
        },
        queryAdvanceKeys: { // 前置查询参数字段
            type: String,
            default: ''
        },
        dataSource: { // 数据源, 前置查询参数字段取值对象，input默认值取值对象
            type: Object,
            default() {
                return {};
            }
        },
        resultKeys: { // 返回的字段
            type: Array,
            required: true
        },
        placeholder: {
            type: String,
            default: '请选择'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        clearable: {
            type: Boolean,
            default: false
        },
        float: {
            type: String,
            default: 'left'
        }
    },
    data() {
        return {
            showTable: false,
            loading: false,
            tableData: [],
            currentRow: {},
            isSelect: false,
            inpValue: '',
            selectVal: ''
        };
    },
    methods: {
        changeSelect(val) {
            this.$emit('changeSelect', val);
        },
        defaultSelectVal(val) {
            this.selectVal = val;
        },
        blur() {
            setTimeout(() => {
                this.showTable = false;
            }, '200');
        },
        clear (e) {
            this.$refs.refInput.blur();
            this.rowClick({}, true);
            setTimeout(() => {
                this.showTable = false;
            }, '200');
        },
        getTableList() {
            let params = '';
            if (this.queryAdvanceKeys) {
                this.queryAdvanceKeys.split(',').forEach(key => {
                    let { 0: sourceProp, 1: destProp } = key.split('#');
                    destProp = destProp || sourceProp;
                    params += ('&' + destProp + '=' + this.dataSource[sourceProp]);
                });
            }
            let params2 = '';
            if (this.inpValue) {
                this.queryKeys.forEach(key => {
                    params2 += ('&' + key + '=' + this.inpValue.trim());
                });
            }
            (this.advanceCascade || '').split(',').forEach(key => {
                let { 0: sourceProp, 1: destProp } = key.split('#');
                destProp = destProp || sourceProp;
                params2 += ('&' + destProp + '=' + this.dataSource[sourceProp]);
            });
            this.loading = true;
            http({
                url: this.prefix + this.url + '?pageSize=10&pageNo=1' + params + params2,
                method: this.method
            }).then(res => {
                if (res && res.code === '0') {
                    this.tableData = res.data.list;
                }
            }).finally(() => {
                this.loading = false;
            });
        },
        focus(data) {
            this.showTable = true;
            this.getTableList();
        },
        input() {
            this.getTableList();
        },
        rowClick(row, showTable = false) {
            this.showTable = showTable;
            this.currentRow = row;
            const res = {};
            this.resultKeys.forEach(key => {
                let { 0: sourceProp, 1: destProp } = key.split('#');
                destProp = destProp || sourceProp;
                res[destProp] = row[sourceProp] || '';
            });
            this.inpValue = res[this.values];
            this.showTable = false;
            this.isSelect = true;
            this.$emit('change', res);
        }
    },
    watch: {
        value(val) {
            if (val || val === '') {
                this.inpValue = val.trim();
            }
        },
        showTable(val) {
            if (!val && !this.isSelect) {
                this.inpValue = '';
            }
            this.isSelect = false;
        }
    }
};
</script>

<style lang="less">
.input-select-component {
  position: relative;

  .el-form-item {
    width: 100% !important;
  }

  .input-select-component-box {
    background: #fff;
    border: 1px solid #eee;
    border-radius: 5px;
    position: absolute;
    z-index: 999;
    top: 30px;
    max-height: 300px;
    max-width: 600px;
    overflow-y: auto;
    box-shadow: 0 0 2px #88888856;
  }
  .input-select-component-box-left {
    left: 0;
  }
  .input-select-component-box-right {
    right: 0;
  }
}
</style>
