import { ref } from '@vue/composition-api';
import { Message } from 'element-ui';

const lang = {
    selectRowMsg: '至少选择一行记录'
};
/**
 * @author ex_huangmt
 * 处理列表相关逻辑
 *### ListClass暴露方法
 *| 方法 | 参数         | 默认值         | 参数说明        | 返回值      | 备注                       |
 *| :--------              |-------------------------| ------------------------|---------------------------| ------------- | ---------------------------------|
 *| `getList()`| data, lazyLoad | { }(必须), false(必须)| 列表额外参数, 懒加载  |     | 此方法为protected（子类不要重写此方法！！）|
 *| `sizeChange()`             | newSize                 |                         | 每页数量                   |               | 响应分页大小变化，并加载列表        |
 *| `pageChange()`             | newPage                 |                         | 页码                      |                | 响应页码变化，并加载列表           |
 *| `searchList()`             | searchParams            | { }                     | 搜索参数                   |               | 响应搜索按钮点击，并加载列表        |
 *| `handleSelectedRow()`      | data                    | [ ]                     | 被选中的所有行             |                | 响应表格选中，保存已选中的行到selectedRows|
 *| `beforeGetList()`          | condition               |                         | 搜索参数                  |处理后的condition(必须),false则终止获取列表| （钩子，可重写）发起列表搜索前置操作，传入所有的搜索参数|
 *| `afterGetList()`           | res                     |                         | 列表返回的数据             |               | （钩子，可重写）获取列表后的后置回调操作|
 *| `isCheckedAtLeastOneRow()` | msg                     | null                    | 一行都没有选中的提示消息    |               | 用于批量操作前置检查                 |
 *| `updateListForeion()`      | foreignModel            |                         | 外键参数 { }              |               | 更新列表外键参数，清空搜索条件，并重加载列表                 |
 *
 *### ListClass暴露响应式变量
 *| 参数              | 参数类型          | 说明                      | 备注          |
 *| --------         | --------         | ---------------          | --------      |
 *| pageNo            | ref( 1 )         | 当前页码                  |              |
 *| pageTotal         | ref( 0 )         | 总记录数                  |              |
 *| pageSize          | ref( 10 )        | 每页数量                  |              |
 *| tableLoading      | ref( false )     | 表格是否正在加载           |              |
 *| totalData         | ref( [ ] )       | 列表行记录数据             |              |
 *| selectedRows      | ref( [ ] )       | 列表被选中的行             |              |
 *| searchModel       | ref( { })        | 搜索参数                  |              |

*### ListClass暴露普通变量
*| 参数              | 参数类型          | 说明                      | 备注          |
*| --------         | --------         | ---------------          | --------      |
*| pageNoName       | String           | 页码参数名称                 |     pageNo   |
*| pageTotal         | String         | 每页大小参数名称                  |   pageSize    |

*/
class ListClass {
     #privateV = null;
     /**
     *### 构造参数
     * | 参数名称 | 类型         | 默认值         | 参数说明        |
     * | :--------  |-----------| ---------      |---------       |
     * | Options.Api.listApi | func| null        | 列表请求Api |
     * | Options.foreignModel | Object| { }        | 列表外键数据 |
     * | Options.pageNoName | String| 'pageNo'        | 页码参数名 |
     * | Options.pageSizeName | String| 'pageSize'        | 每页大小参数名 |
     *
     *### ListClass暴露方法
     *| 方法 | 参数         | 默认值         | 参数说明        | 返回值      | 备注                       |
     *| :--------              |-------------------------| ------------------------|---------------------------| ------------- | ---------------------------------|
     *| `getList()`| data, lazyLoad | { }(必须), false(必须)| 列表额外参数, 懒加载  |     | 此方法为protected（子类不要重写此方法！！）|
     *| `sizeChange()`             | newSize                 |                         | 每页数量                   |               | 响应分页大小变化，并加载列表        |
     *| `pageChange()`             | newPage                 |                         | 页码                      |                | 响应页码变化，并加载列表           |
     *| `searchList()`             | searchParams            | { }                     | 搜索参数                   |               | 响应搜索按钮点击，并加载列表        |
     *| `handleSelectedRow()`      | data                    | [ ]                     | 被选中的所有行             |                | 响应表格选中，保存已选中的行到selectedRows|
     *| `beforeGetList()`          | condition               |                         | 搜索参数                  |处理后的condition(必须),false则终止获取列表| （钩子，可重写）发起列表搜索前置操作，传入所有的搜索参数|
     *| `afterGetList()`           | res                     |                         | 列表返回的数据             |               | （钩子，可重写）获取列表后的后置回调操作|
     *| `isCheckedAtLeastOneRow()` | msg                     | null                    | 一行都没有选中的提示消息    |               | 用于批量操作前置检查                 |
     *| `updateListForeion()`      | foreignModel            |                         | 外键参数 { }              |               | 更新列表外键参数，清空搜索条件，并重加载列表                 |
     *
     *### ListClass暴露响应式变量
     *| 参数              | 参数类型          | 说明                      | 备注          |
     *| --------         | --------         | ---------------          | --------      |
     *| pageNo            | ref( 1 )         | 当前页码                  |              |
     *| pageTotal         | ref( 0 )         | 总记录数                  |              |
     *| pageSize          | ref( 10 )        | 每页数量                  |              |
     *| tableLoading      | ref( false )     | 表格是否正在加载           |              |
     *| totalData         | ref( [ ] )       | 列表行记录数据             |              |
     *| selectedRows      | ref( [ ] )       | 列表被选中的行             |              |
     *| searchModel       | ref( { })        | 搜索参数                  |              |
     */
     constructor(Options = {}) {
         this.#privateV = 'test';

         this.listApi = Options.Api.listApi || null;

         // 当前页码
         this.pageNo = ref(1);
         // 列表总数量
         this.pageTotal = ref(0);
         // 每页数量
         this.pageSize = ref(10);

         // 列表加载状态
         this.tableLoading = ref(false);
         // 列表数据
         this.totalData = ref([]);
         // 被选中的行
         this.selectedRows = ref([]);
         // 列表搜索参数
         this.searchModel = ref({});
         // 列表外键从参数 （不随搜索器变化的参数，基础搜索参数）
         this.foreignModel = ref(Options.foreignModel || {});

         // 页码参数名字
         this.pageNoName = Options.pageNoName || 'pageNo';
         // 每页数量参数名字
         this.pageSizeName = Options.pageSizeName || 'pageSize';
     }

    /**
     * 设置外键信息并重加载列表
     * @param foreignModel { key:value }
     */
    updateListForeion = (foreignModel) => {
        // 重置分页
        this.pageNo.value = 1;
        // 设置新的外键参数
        this.foreignModel.value = foreignModel;
        // 重置搜索参数
        this.searchModel.value = {};
        // 重新加载列表
        this.getList({}, false);
    }

    /**
     * 分页大小响应
     * @param newSize:Number 每页数量
     */
    sizeChange = (newSize) => {
        this.pageNo.value = 1;
        this.pageSize.value = newSize;
        this.getList();
    };
    /**
     * 页码变化响应
     * @param newPage:Number 页码
     */
    pageChange = (newPage) => {
        this.pageNo.value = newPage;
        this.getList();
    };

    /**
     * 搜索列表
     * @param searchParams:Object  搜索参数{ }
     */
    searchList = (searchParams) => {
        this.pageNo.value = 1;
        this.searchModel.value = searchParams;
        this.getList();
    }
    /**
     * 表格选中的行
     * @param selectedRow:Array  选中的行数据 [{},{}]
     */
    handleSelectedRow = (selectedRows) => {
        this.selectedRows.value = selectedRows;
    };

    /**
     * 用于懒加载，当前分页加一
     */
    lazyGetList = (data = {}) => {
        this.pageNo.value += 1;
        this.getList(data, true);
    };

    /**
     * 获取列表前置操作  用于处理搜索参数
     * @param condition:Object    发起请求前的搜索参数
     * @returns Object:{ } | Boolean:false        处理后的搜索参数  false则中断获取列表
     */
    beforeGetList (condition) {
        return condition;
    }

    /**
     *  protected  （不允许重写!!!）
     * 加载列表信息
     * @param data:Object          列表搜索参数
     * @param lazyLoad:Boolean      true则懒加载
     */
    getList = (data = {}, lazyLoad = false) => {
        if (!this.listApi) {
            throw new TypeError('请先配置Options.Api.listApi');
        }

        // 分页参数为可变参数名
        const condition = {
            [this.pageNoName]: this.pageNo.value,
            [this.pageSizeName]: this.pageSize.value,
            ...this.searchModel.value,
            ...this.foreignModel.value,
            ...data
        };
        this.pageNo.value = condition.pageNo;
        this.pageSize.value = condition.pageSize;

        const conditionData = this.beforeGetList(JSON.parse(JSON.stringify(condition)));
        if ((conditionData !== false) && (typeof conditionData !== 'object')) {
            throw new TypeError('beforeGetList 方法必须返回 Object:{} 或 Boolean:false');
        }
        // 钩子方法返回false，则不发起列表请求
        if (conditionData === false) {
            return false;
        } else {
            this.tableLoading.value = true;
            this.listApi(conditionData).then(res => {
                if (lazyLoad === false) {
                    this.afterGetList(res);
                } else {
                    this.afterGetListLazy(res);
                }
            }).finally(res => {
                this.tableLoading.value = false;
            });
        }
    };

    /**
     * 获取列表成功回调，常规赋值
     * @param res：JSON   列表接口返回的数据
     */
    afterGetList (res) {
        if (+res.code === 0) {
            this.totalData.value = res.data.list;
            this.pageTotal.value = res.data.totalCount;
        }
    };

    /**
     * 懒加载列表成功回调，如果是懒加载，则push进入List
     * @param res：JSON    列表接口返回的数据
     */
    afterGetListLazy (res) {
        if (+res.code === 0) {
            this.totalData.value.push(res.data.list);
            this.pageTotal.value = res.data.totalCount;
        }
    };

    /**
     * 用于检查是否至少选择了一行数据  用于批量操作的前置检查
     * @param mag :String               没有选择行时候的提示消息
     * @returns Boolean      返回true则至少选择了一行
     */
    isCheckedAtLeastOneRow = (msg = null) => {
        if (this.selectedRows.value.length < 1) {
            Message.info(msg || lang.selectRowMsg);
            return false;
        } else {
            return true;
        }
    }
}

export { ListClass };
