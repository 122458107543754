<template>
    <div class="m-otp-cascader-content">
        <div class="i-cascader-component">
            <el-cascader
                v-if="isShow"
                size='mini'
                :placeholder="$translate(placeholder)"
                :props="propsConfig"
                :disabled="disabled"
                ref="cascader"
                :collapse-tags="basicProps.collapseTags"
                v-model="addressArr"
                @input="change"
                @visible-change="visibleChange"
                :clearable="clearable"
            ></el-cascader>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import { getAreaApi } from '../api.js';
import { I18nMixins } from '@/components/mixins/i18n';
import { MILLISECOND, SIXTY, TIMEOFDAY } from '@/components/utils/constant';
export default {
    mixins: [I18nMixins],
    props: {
        value: {
            type: [Array, String],
            defalut() {
                return [];
            }
        },
        basicProps: {
            type: Object,
            default() {
                return {};
            }
        },
        minLayers: {
            type: Number,
            default() {
                return 0;
            }
        },
        placeholder: String,
        disabled: {
            type: Boolean,
            default() {
                return false;
            }
        },
        clearable: {
            type: Boolean,
            default() {
                return true;
            }
        },
        // 地址选择器缓存时效（毫秒）
        difference: {
            type: Number,
            default() {
                return MILLISECOND * SIXTY * SIXTY * TIMEOFDAY; // 默认24小时
            }
        },
        needCountry: {
            type: Boolean,
            default() {
                return false;
            }
        }
    },
    data() {
        const _this = this;
        return {
            isShow: true,
            OINDEX: 4,
            HASCOUNTRYINDEX: 5,
            COUNTRY_AREA_TYPE: ['PLACE_COUNTRY', 'PLACE_PROVINCE', 'PLACE_CITY', 'PLACE_DISTRICT', 'PLACE_STREET'],
            AREA_TYPE: ['PLACE_PROVINCE', 'PLACE_CITY', 'PLACE_DISTRICT', 'PLACE_STREET'],
            CODE_LENGHT: {
                PROVINCE: 3,
                CITY: 5,
                DISTRICT: 7,
                STREET: 10
            },
            addressArr: [],
            addressOptions: [],
            AREA_MAP: new Map(),
            extendProps: {
                lazy: true,
                lazyLoad (node, resolve) {
                    const level = node.level;
                    const params = {
                        // ebplType: _this.AREA_TYPE[level]
                        ebplType: _this.needCountry ? _this.COUNTRY_AREA_TYPE[level] : _this.AREA_TYPE[level]
                    };
                    if (level === 0) {
                        params.ebplParentPmCode = _this.needCountry ? undefined : '100000';
                    } else {
                        params.ebplParentPmCode = node.value;
                    }
                    getAreaApi(params, _this.difference).then(res => {
                        const nodes = res.data.map(item => {
                            // 将查询之后的区域对象放到一个map中，给地址code解析地址名称用
                            _this.AREA_MAP.set(item.ebplCode, item.ebplNameCn);
                            return _this.optionsFormat(item, level + 1 >= _this.leafIndex);
                        });
                        resolve(nodes);
                        // 除了有默认的时候不会调用lazyLoad，其他数据变化都会执行，所以在这里进行单选框的隐藏判断
                        if (level < _this.minLayers - 1) {
                            _this.disabledRadio(level);
                        }
                    });
                }
            }
        };
    },
    methods: {
        optionsFormat(item, isLeaf) {
            return {
                label: item.ebplNameCn,
                value: item.ebplCode,
                leaf: isLeaf
            };
        },
        disabledRadio(level) {
            // 只有选择任意级的时候才需要对radio禁用
            if (this.basicProps.checkStrictly) {
                this.$nextTick(() => {
                    if (this.$refs.cascader.panel.$el.getElementsByClassName('el-cascader-menu__list')[level]) {
                        const menuListDom = this.$refs.cascader.panel.$el.getElementsByClassName('el-cascader-menu__list')[level];
                        menuListDom.className = menuListDom.className.replace(' disable-radio', '') + ' disable-radio';
                    }
                });
            }
        },
        // 下拉框监听事件
        visibleChange(val) {
            if (val) {
                if (!this.value) {
                    // 每次打开下拉框的时候重新从一级选起
                    this.addressOptions = this.addressOptions || this.addressOptions.map(item => {
                        Reflect.deleteProperty(item, 'children');
                        return item;
                    });
                } else {
                    // 传入的地址数组长度和可选级数的限制（minLayers）进行比较，取长度较短者
                    const length = this.value.length > this.minLayers - 1 ? this.minLayers - 1 : this.value.length;
                    // 有默认值的时候进行单选框的显示或隐藏判断
                    for (let i = 0; i < length; i++) {
                        this.disabledRadio(i);
                    }
                }
            }
        },
        async init(value) {
            const { PROVINCE, CITY, DISTRICT, STREET } = this.CODE_LENGHT;
            // const COUNTRY_CODE_CHINA = 100000;
            // 判断传进来的v-model的值是数组或者字符串
            if (_.isString(value)) {
                // 对传入的字符串进行是省市区哪个级别的处理
                this.addressArr = [PROVINCE, CITY, DISTRICT, STREET].map(item => {
                    return value.length >= item ? value.substr(0, item) : '';
                }).filter(item => item);
            } else if (_.isArray(value)) {
                this.addressArr = value;
            }
        },

        // 输入框变化监听事件
        change(val) {
            this.$emit('input', val);
            this.$emit('getAddress', this.addressFormat(this.basicProps.multiple ? val : [val]));
            if (this.basicProps.checkStrictly && !this.basicProps.multiple) {
                this.$refs.cascader.toggleDropDownVisible(false);
            }
        },
        // 提供给外部的解析地址code的函数，入参：['code1', 'code2',...], 出参：['name1','name2',...]
        addressFormat(arr) {
            const result = [];
            arr.forEach(item => {
                if (_.isArray(item)) {
                    result.push(item.map(i => this.AREA_MAP.get(i)));
                }
            });
            return result;
        }
    },
    computed: {
        propsConfig() {
            return { ...this.basicProps, ...this.extendProps };
        },
        leafIndex() {
            return this.propsConfig.checkStrictly ? this.LEAF_INDEX : this.minLayers;
        },
        LEAF_INDEX() {
            return this.needCountry ? this.HASCOUNTRYINDEX : this.OINDEX;
        }
    },
    watch: {
        value: {
            handler(val) {
                if (val) {
                    this.init(val);
                    // 因为级联组件不能正常处理value值的变化而自动加载数据，所以销毁组件重新实例化
                    if (!this.basicProps.multiple) {
                        this.$nextTick(() => {
                            // value变化的时候 先让组件内部的逻辑处理完毕，再执行销毁（不能马上执行销毁）
                            this.isShow = false;
                            this.$nextTick(() => {
                                // 销毁完成后，再重新实例化组件
                                this.isShow = true;
                            });

                            // this.$refs['cascader'].$refs['panel'].initStore();
                            // this.$refs['cascader'].$refs['panel'].syncActivePath();
                        });
                    }
                } else {
                    this.addressArr = [];
                }
            },
            immediate: true
        }
    }
};
</script>
<style lang="less">
.m-otp-cascader-content{
    width:100%;
    .i-cascader-component{
        width:100%;
        .el-cascader{
            width:100%;
            .el-cascader__tags{
                right: 50px;
                // flex-wrap: nowrap;
            }
        }
    }
}
.disable-radio {
    .el-radio{
        display: none;
    }
}
</style>
