<template>
    <div class="model-table-search btn-bg">
        <el-row class="search-box">
            <el-col v-for="(item,index) in fixedList" :key="index" :span="item.span" :class="{ 'is-fussy': item.isFussy }" :title="item.name || ''">
                <div class="search">
                    <el-input v-if="item.type === 'input'"
                        size="mini"
                        :placeholder="$translate(item.name)"
                        :clearable="_.get(item, 'clearable', true)"
                        :disabled="_.get(item, 'disabled', false)"
                        v-model="searchData[item.value]" @keyup.enter.native="onSearchChange()"
                        @change="elementChange(item)"
                        @input="elementChange(item)">
                    </el-input>
                    <inputSelect v-else-if="item.type === 'inputSelect'"
                        :values="item.value"
                        :value="searchData[item.value]"
                        :columns="item.columns"
                        :prefix="_.get(item, 'prefix', '/api-auth')"
                        :url="item.url"
                        :method="_.get(item, 'method', 'get')"
                        :queryKeys="item.queryKeys"
                        :queryAdvanceKeys="_.get(item, 'queryAdvanceKeys', '')"
                        :dataSource="_.get(item, 'dataSource', {})"
                        :resultKeys="item.resultKeys"
                        :placeholder="$translate(item.name)"
                        :disabled="_.get(item, 'disabled', false)"
                        :clearable="_.get(item, 'clearable', true)"
                        @change="handleMdInputChange($event, item)"
                        >
                    </inputSelect>
                    <el-select v-else-if="item.type === 'select'"
                        size="mini"
                        :clearable="_.get(item, 'clearable', true)"
                        :filterable="_.get(item, 'filterable', true)"
                        v-model="searchData[item.value]"
                        :multiple="item.multiple?item.multiple:false"
                        :placeholder="$translate(item.name)"
                        collapse-tags
                        @keyup.enter.native="onSearchChange()"
                        :disabled="_.get(item, 'disabled', false)"
                        @change="elementChange(item)">
                        <el-option
                            v-for="(val, index) in item.options"
                            :key=index
                            :label="val.label"
                            :value="val.value">
                        </el-option>
                    </el-select>
                    <el-cascader v-else-if="item.type === 'cascader'"
                        size="mini"
                        v-model="searchData[item.value]"
                        :options="item.options"
                        :props="_.get(item, 'props', '')"
                        collapse-tags
                        :clearable="_.get(item, 'clearable', true)"
                        :filterable="_.get(item, 'filterable', true)"
                        :change-on-select="item.changeOnSelect"
                        :disabled="_.get(item, 'disabled', false)"
                        :placeholder="$translate(item.name)"
                        @change="elementChange(item)">
                    </el-cascader>
                    <el-date-picker v-else-if="item.type === 'time'"
                        size="mini"
                        class='text-box'
                        v-model="searchData[item.value]"
                        :clearable="_.get(item, 'clearable', true)"
                        :format="_.get(item, 'format', 'yyyy-MM-dd HH:mm:ss')"
                        :picker-options="handlePickerOptions(item)"
                        :type="item.time?'daterange':'datetimerange'"
                        :disabled="_.get(item, 'disabled', false)"
                        unlink-panels
                        :range-separator="$translate('component.E202007300014', { defaultText: '至' })"
                        :start-placeholder="item.startPlaceholder ? $translate(item.startPlaceholder) : $translate('component.E202007300015', { defaultText: '开始日期' })"
                        :end-placeholder="item.endPlaceholder ? $translate(item.endPlaceholder) : $translate('component.E202007300016', { defaultText: '结束日期' })"
                        :default-time="['00:00:00', '23:59:59']"
                        @change="elementChange(item)">
                    </el-date-picker>
                    <lots-md-input
                        v-else-if="item.type === 'advance'"
                        :label="searchData[item.value]"
                        :disabled="_.get(item, 'disabled', false)"
                        :config="item"
                        :cascadeData="searchData"
                        @change="handleMdInputChange($event, item)"
                    ></lots-md-input>
                    <otpAdvanceMulti
                        class="advance-input"
                        v-else-if="item.type === 'advanceMulti'"
                        :ref="item.value"
                        :params="item"
                        @getAdvanceValue="(data) => { getMultiInfo(data, item); }"
                        :disabled="_.get(item, 'disabled', false)"
                        :mValue="searchData[item.value]"
                        :formData="searchData">
                    </otpAdvanceMulti>
                    <lots-user-select
                        v-else-if="item.type === 'advanceUser'"
                        :label="searchData[item.value]"
                        :disabled="_.get(item, 'disabled', false)"
                        :config="item"
                        :cascadeData="searchData"
                        @change="handleMdInputChange($event, item)"
                    ></lots-user-select>
                    <addressSelect v-else-if="item.type === 'addressSelect'"
                        v-model="searchData[item.value]"
                        :minLayers="item.minLayers"
                        :placeholder="$translate(item.name)"
                        :disabled="_.get(item, 'disabled', false)"
                        :basicProps="item.config"
                        @input="elementChange(item)">
                    </addressSelect>
                </div>
                <span class="input-append-fussy" v-if="item.isFussy">
                    <el-checkbox size="mini" v-model="searchData[item.value + 'Fussy']">{{$translate('component.E202007300017', { defaultText: '模糊' })}}</el-checkbox>
                </span>
            </el-col>
            <el-col :span="searchBtnBoxSpan" class="search-btn-box">
                <lots-button class="search-btn" type="primary" size="mini" @click="onSearchChange()" :loading="loading" :disabled="isDisabled" icon="iconfont icon-search_Inquire">
                    {{$translate('component.E202007300018',{defaultText: defaultSearchText})}}
                </lots-button>
                <advance class="advanced-btn" v-if="showAdvanceBtn" :search-key="searchKey"
                    :searchData="searchData" :fields="fields" :keyMaps="keyMap"
                    @search="onSearchChange" @elementChange="elementChange"></advance>
                <slot name="btn-append" v-bind="{ fields }"></slot>
            </el-col>
        </el-row>
        <advance-dialog ref="advanceDialog" @confirm="detailSearchSure"></advance-dialog>
    </div>
</template>
<script>
import dayjs from 'dayjs';
import _ from 'lodash';
import Advance from './Advance';
import { dictWithUrl } from '../api';
import addressSelect from '@/components/lots/otpCascader2';
import advanceDialog from '@/components/lots/otpAdvanceDialog';
import lotsMdInput from '@/components/lots/lotsMdInput';
import lotsButton from '@/components/lots/lotsButton';
import inputSelect from '@/components/lots/inputSelect/Index';
import lotsUserSelect from '@/components/lots/lotsUserSelect';
import otpAdvanceMulti from '@/components/lots/otpAdvanceMulti/index.vue';
import { I18nMixins } from '@/components/mixins/i18n';
export default {
    name: 'SearchBox',
    mixins: [I18nMixins],
    components: { Advance, addressSelect, advanceDialog, lotsMdInput, lotsButton, inputSelect, lotsUserSelect, otpAdvanceMulti },
    props: {
        defaultSearchText: {
            type: String,
            default() {
                return '搜索';
            }
        },
        isDisabled: {
            type: Boolean,
            default() {
                return false;
            }
        },
        searchKey: String,
        fields: {
            type: Array,
            default() {
                return [];
            }
        },
        showSearchAdvanceBtn: {
            type: String,
            default() {
                return '';
            }
        },
        searchBtnBoxSpan: {
            type: Number,
            default: 4
        }
    },
    data() {
        return {
            searchData: {},
            pickerOptions: {
                shortcuts: [{
                    text: this.$translate('component.E202007300011', { defaultText: '最近一周' }),
                    onClick(picker) {
                        const DAYS_A_WEEK = 7;
                        const start = dayjs().startOf('day').subtract(DAYS_A_WEEK - 1, 'd').toDate();
                        const end = dayjs().endOf('day').toDate();
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: this.$translate('component.E202007300013', { defaultText: '最近一个月' }),
                    onClick(picker) {
                        const start = dayjs().startOf('day').subtract(1, 'M').toDate();
                        const end = dayjs().endOf('day').toDate();
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            loading: false,
            keyMap: {}
        };
    },
    computed: {
        _() {
            return _;
        },
        fixedList() {
            // 默认查询的列表
            return this.fields.filter(o => o.isFixed);
        },
        showAdvanceBtn() {
            // 电商登记页面需隐藏高级按钮
            if (this.showSearchAdvanceBtn) return false;

            // 如果固定的搜索项小于全部的搜索项，则显示高级按钮
            else return this.fixedList.length < this.fields.length;
        }
    },
    methods: {
        handlePickerOptions(item) {
            return { ...this.pickerOptions, ...item.pickerOptions };
        },
        initSearchData() {
            const obj = {};
            this.fields.forEach(item => {
                obj[item.value] = '';
            });
            this.searchData = obj;
        },
        elementChange(column) {
            this.$emit('elementChange', { column, searchData: this.searchData });
        },
        clear() {
            this.searchData = {};
        },
        // 设置默认查询参数
        setQueryParams(initialVal) {
            this.searchData = Object.assign({}, this.searchData, _.cloneDeep(initialVal));
        },
        submitSearch(isExport = false) {
            return this.onSearchChange(null, isExport);
        },
        detailSearch(index, current, type, searchData) {
            // 高级搜索级联操作，current.advanceCascade为需要级联的参数的key的集合，例如advanceCascade: key1,key2,key3
            const advanceCascade = {};
            if (_.isString(current.advanceCascade)) {
                let advanceArray = [];
                advanceArray = current.advanceCascade.split(',');
                advanceArray.map((item) => {
                    let { 0: sourceProp, 1: destProp } = item.split('#');
                    destProp = destProp || sourceProp;
                    advanceCascade[destProp] = this.searchData[sourceProp];
                });
            }
            this.$refs.advanceDialog.show(true, current, advanceCascade);
        },
        detailSearchSure(data) {
            // 将返回数据添加到map里，用于高级搜索清空时，顺带清空其他关联属性
            const keyArr = Object.keys(data);
            if (keyArr.length > 0) {
                keyArr.forEach(key => {
                    // this.keyMap[key] = keyArr;
                    this.$set(this.keyMap, key, keyArr);
                });
            }
            this.searchData = { ...this.searchData, ...data };
        },
        onSearchChange(data, isExport = false) {
            const searchDataCopy = _.cloneDeep(this.searchData);
            const searchData = data ? _.cloneDeep(data) : searchDataCopy;
            for (const key in searchData) {
                if (searchData.hasOwnProperty(key)) {
                    const temp = searchData[key];
                    if (_.isString(temp)) {
                        // searchData[key] = temp.replace(/\s*/g, '');
                        // 只去除前后空格
                        searchData[key] = _.trim(temp);
                    }
                }
            }
            if (isExport) {
                return searchData;
            } else {
                this.$emit('search-change', searchData);
            }
        },
        getOptionList() {
            const optionNums = this.fields.length > 0 ? this.fields.map(item => item.optionNum).filter(i => i).join(',') : '';
            if (!optionNums) return;
            dictWithUrl(optionNums).then(res => {
                if (res.code === '0') {
                    this.fields.forEach(item => {
                        if (res.data[item.optionNum]) {
                            item.options = Object.entries(res.data[item.optionNum]).map(arr => {
                                return {
                                    value: arr[0],
                                    label: arr[1]
                                };
                            });
                        }
                    });
                    this.$forceUpdate();
                }
            });
        },
        // 高级输入框的关闭按钮点击
        resetAdvanceParams(prop) {
            const keyArr = this.keyMap[prop];
            // 把属性相关联的字段置空
            keyArr.forEach(key => {
                this.searchData[key] = '';
            });
        },
        handleMdInputChange(data, column) {
            this.searchData = { ...this.searchData, ...data };
            this.elementChange(column);
        },
        /**
         * 多选弹窗回调获取信息
         */
        getMultiInfo(data, item) {
            const params = {};
            if (data && Array.isArray(data)) {
                if (data.length) {
                    const keyArr = Object.keys(data[0]);
                    keyArr.forEach(key => {
                        if (!params[key + 's']) { params[key + 's'] = []; }
                        data.forEach(subItem => {
                            params[key + 's'].push(subItem[key]);
                        });
                        params[key + 's'] = params[key + 's'].join();
                    });
                } else {
                    item.cbParams.forEach(keySub => {
                        const arr = keySub.split('#');
                        params[arr[arr.length - 1] + 's'] = null;
                    });
                }
            }
            Object.assign(this.searchData, params);
            this.$emit('elementChange', { searchData: this.searchData, column: item });
        }
    },
    created() {
        this.initSearchData();
        this.getOptionList();
    }
};
</script>
<style lang="less">
.model-table-search {
  .search-box {
    flex-wrap: wrap;

    .is-fussy {
      display: flex;

      .search {
        flex: auto;
      }

      .input-append-fussy {
        flex: none;
        flex-basis: 65px;
        align-self: baseline;
        margin-top: 4px;

        .el-checkbox__label {
          padding-left: 4px;
        }
      }
    }

    .search {
      margin: 0 8px 10px 0;
    }

    .advanced-btn {
      margin-left: 10px;
    }

    .advance-input {
      .el-input__inner {
        padding-right: 20px;
      }

      .el-input-group__append {
        text-align: center;
        padding: 0 14px;

        .el-button {
          padding: 8px;
        }
      }
    }

    .el-select {
      width: 100%;
    }
  }

  .text-box {
    position: relative;
    font-size: 12px;
    width: 100%;
  }
  .search-btn-box {
      width: auto;
  }
}
</style>
