<template>
    <el-pagination
        v-bind="$attrs"
        v-on="$listeners"
        size="mini"
        :page-size="pageSizeLocal"
        :page-sizes="[10, 20, 50, 100]"
        :layout="filterLayout">
    </el-pagination>
</template>
<script>
export default {
    name: 'lotsPagination',
    inheritAttrs: false,
    props: {
        layout: {
            type: String,
            default: 'sizes, prev, pager, next'
        },
        pageSize: {
            type: Number,
            default: 10
        }
    },
    data() {
        return {
            filterLayout: 'sizes, prev, pager, next'
        };
    },
    created() {
        const layoutArr = {
            sizes: 'sizes',
            prev: 'prev',
            next: 'next',
            pager: 'pager',
            jumper: 'jumper',
            total: 'total'

        };
        const layArr = this.layout.split(',');
        const filterLayout = [];
        for (let i = 0; i < layArr.length; i++) {
            const layOne = layArr[i].replace(/^\s+|\s+$/g, '');
            if (layoutArr[layOne]) {
                filterLayout.push(layOne);
            }
        }
        this.filterLayout = filterLayout.join(',');
    },
    computed: {
        pageSizeLocal() {
            return this.pageSize;
        }
    }
};
</script>
