<template>
    <el-dialog
        custom-class="user-seletor-dialog"
        title="人员选择器"
        :visible.sync="visible"
        append-to-body
        width="840px"
    >
        <div class="content">
            <div class="content-tree">
                <div class="tree-search">
                    <span class="title"  v-if="hasOrganization">
                        <span class="iconfont icon-quanxian-zuzhijiagou"></span>
                        组织架构
                    </span>
                    <div class="search-box">
                        <el-input
                            size="mini"
                            placeholder="搜索成员"
                            :clearable="true"
                            v-model="searchValue"
                            @keyup.enter="search"
                        >
                        </el-input>
                        <el-button
                            type="primary"
                            :disabled="loadingTree"
                            @click="search"
                            >搜 索</el-button
                        >
                        <el-button
                            class="addAllBtn"
                            v-show="isMultCheck && memberList.length"
                            @click="selectAll">
                            全选
                        </el-button>
                    </div>
                </div>
                <div class="tree-org" v-loading="loadingTree">
                    <div class="tree-org-treeBox" v-if="hasOrganization">
                        <el-tree
                            v-if="treeShow"
                            ref="tree"
                            node-key="code"
                            highlight-current
                            :props="defaultProps"
                            lazy
                            :load="loadNode"
                            @node-click="nodeClick"
                        >
                            <template #default="{ node }">
                                <span class="iconfont icon-topbar_file_medium">
                                    <span class="nodeName">{{node.label}}</span>
                                </span>
                            </template>
                        </el-tree>
                    </div>
                    <div class="tree-org-memberBox">
                        <div
                            class="tree-org-memberBox-line"
                            v-for="(item, index) in memberList"
                            :key="index"
                        >
                            <el-tooltip placement="top" :show-after="1200">
                                <template v-slot:content
                                >{{
                                        item.userName
                                    }}&nbsp;&nbsp;&nbsp;&nbsp;{{
                                        item.mainPostName
                                    }}<br /><br />{{ item.orgName }}
                                </template>
                                <el-radio v-if="!isMultCheck" v-model="radioCheck" @change="radioChange(item)" :label='item.userCode'>
                                    <div class="tree-org-memberBox-info">
                                        {{ item.userCode }}
                                    </div>
                                    <div class="tree-org-memberBox-info">
                                        <span
                                            v-if="item.sex === 1"
                                            class="iconfont icon-iflow-nanshi male"
                                        ></span>
                                        <span
                                            v-else
                                            class="iconfont icon-iflow-nvshi female"
                                        ></span>
                                        <span class="infoName">{{ item.userName }}</span>
                                    </div>
                                    <div class="tree-org-memberBox-info">
                                        {{ item.mainPostName }}
                                    </div>
                                </el-radio>
                                <el-checkbox
                                    v-else
                                    v-model="item.isCheck"
                                    @change="(flag) => {selectChange(item, flag);}"
                                >
                                    <div class="tree-org-memberBox-info">
                                        {{ item.userCode }}
                                    </div>
                                    <div class="tree-org-memberBox-info">
                                        <span
                                            v-if="item.sex === 1"
                                            class="iconfont icon-iflow-nanshi male"
                                        ></span>
                                        <span
                                            v-else
                                            class="iconfont icon-iflow-nvshi female"
                                        ></span>
                                        <span class="infoName">{{ item.userName }}</span>
                                    </div>
                                    <div class="tree-org-memberBox-info">
                                        {{ item.mainPostName }}
                                    </div>
                                </el-checkbox>
                            </el-tooltip>
                        </div>
                    </div>
                </div>
            </div>
            <div class="seletedContent">
                <div class="seletedContent-num">
                    <div class="select-desc">
                        已选
                        <span style="color: #66b1ff">{{ selectList.length }}</span>
                        人
                    </div>
                    <el-button type="text" class="clear" @click="clear"
                    >清空</el-button
                    >
                </div>
                <div class="seletedContent-member">
                    <el-tooltip
                        :show-after="1200"
                        class="select-line"
                        placement="top"
                        v-for="(item, index) in selectList"
                        :key="index"
                    >
                        <template v-slot:content
                        >{{ item.userName }}&nbsp;&nbsp;&nbsp;&nbsp;{{
                                item.mainPostName
                            }}<br /><br />{{ item.orgName }}</template
                        >
                        <div class="line-content">
                            <div style="width: 80%; paddingleft: 20px">
                                <div
                                    v-if="item.sex === 1"
                                    class="iconfont icon-iflow-nanshi male"
                                ></div>
                                <div
                                    v-else
                                    class="iconfont icon-iflow-nvshi female"
                                ></div>
                                <span style="padding-left: 8px">{{
                                        item.userName
                                    }}</span>
                            </div>
                            <div
                                style="width: 20%; text-align: right"
                                @click="del(item, index)"
                            >
                                <em class="el-icon-close"></em>
                            </div>
                        </div>
                    </el-tooltip>
                </div>
            </div>
        </div>
        <template v-slot:footer>
            <el-button size="mini" type="primary" @click="save">确 定</el-button>
            <el-button size="mini" @click="visible = false">取 消</el-button>
        </template>
    </el-dialog>
</template>
<script>
import { organizations, searchUser } from './api';

export default {
    name: 'lotsUserSelectorDialog',
    // 定义抛出的事件名称
    emits: ['confirm'],
    props: {
        draggable: {
            type: Boolean,
            default: false
        },
        // 是否展示组织机构树
        hasOrganization: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            visible: false,
            treeShow: false,
            loadingTree: false,
            isMultCheck: false,
            searchValue: '',
            defaultProps: {
                children: 'children',
                label: 'name'
            },
            radioCheck: '', // 单选框当前选择项
            memberList: [],
            selectList: [],
            currentRow: {},
            filterOrg: ''
        };
    },
    methods: {
        /**
         * @description: 调用显示弹窗
         * @param {Boolean} isMultCheck 是否多选
         * @param {String} filterOrg 过滤组织编码
         * @param {Array} defalut 默认值回显
         */
        show(isMultCheck = false, filterOrg = '', defalut = []) {
            const copyData = JSON.parse(JSON.stringify(defalut));
            this.treeShow = false;
            this.radioCheck = '';
            this.searchValue = '';
            this.filterOrg = filterOrg;
            this.isMultCheck = isMultCheck;
            this.treeShow = true;
            this.visible = true;
            this.selectList = [];
            if (copyData.length) {
                this.memberList = this.memberListCheckBefore(copyData);
                this.selectAll();
            } else {
                this.memberList = [];
            }
        },
        memberListCheckBefore(val = []) {
            val.forEach(item => { item.isCheck = false; });
            return val;
        },
        loadNode(node, resolve) {
            this.loadingTree = true;
            if (node.level === 0) {
                this.resolve = resolve;
                this._organizations({ parentCode: 100000 }).then(
                    (res) => {
                        if (res && res.code === '0' && res.data) {
                            let data = res.data;
                            if (this.filterOrg) {
                                data = data.filter(item => item.code === this.filterOrg);
                            }
                            return resolve(data);
                        } else {
                            return resolve([]);
                        }
                    }
                ).finally(() => {
                    this.loadingTree = false;
                });
                return false;
            }
            return this._organizations({ parentCode: node.data.code }).then((res) => {
                if (res && res.code === '0' && res.data) {
                    return resolve(res.data);
                } else {
                    return resolve([]);
                }
            }).finally(() => {
                this.loadingTree = false;
            });
        },
        async nodeClick(data, node) {
            this.memberList = [];
            this.loadingTree = true;
            await this._organizations({
                tenantFlag: 0,
                withUserFlag: 1,
                code: node.data.code
            }).then((res) => {
                if (res && res.code === '0' && res.data) {
                    this.dataProcess(res.data);
                } else {
                    this.memberList = [];
                }
            }).finally(() => {
                this.loadingTree = false;
            });
        },
        dataProcess(data) {
            const userList = {};
            this.selectList.length && this.selectList.forEach((item) => {
                userList[item.userCode] = true;
            });
            data.forEach((it) => {
                it.users.forEach((member) => {
                    if (userList[member.userCode]) {
                        member.isCheck = true;
                    }
                    this.memberList.push(member);
                });
            });
        },
        selectChange(row, flag) {
            flag && this.selectList.unshift(row);
            !flag && this.selectList.forEach((item, idx) => {
                if (item.userCode === row.userCode) {
                    this.selectList.splice(idx, 1);
                }
            });
        },
        radioChange(row) {
            this.selectList.splice(0, 1);
            this.selectList.push(row);
        },
        selectAll() {
            this.memberList.length && this.memberList.forEach((item) => {
                if (!item.isCheck) {
                    item.isCheck = true;
                    this.selectList.push(item);
                }
            });
        },
        async search() {
            const userList = {};
            this.selectList.length && this.selectList.forEach((item) => {
                userList[item.userCode] = true;
            });
            this.memberList = [];
            this.loadingTree = true;
            await searchUser({ queryParam: this.searchValue }).then((res) => {
                if (res && res.code === '0' && res.data) {
                    res.data.list.forEach((it) => {
                        it.isCheck = userList[it.userCode] ? userList[it.userCode] : false;
                        this.memberList.push(it);
                    });
                }
            }).finally(() => {
                this.loadingTree = false;
            });
        },
        clear() {
            this.selectList = [];
            this.memberList.forEach((item) => {
                item.isCheck = false;
            });
        },
        del(row, index) {
            this.selectList.splice(index, 1);
            this.memberList.forEach((it) => {
                if (it.userCode === row.userCode) {
                    it.isCheck = false;
                }
            });
        },
        /**
         * @description 保存返回选中的人员信息
         */
        save() {
            this.$emit('confirm', this.selectList);
            this.visible = false;
        },
        _organizations(params) {
            return organizations(params).then((res) => res);
        }
    }
};
</script>
<style lang="less">
.user-seletor-dialog {
    ::-webkit-scrollbar-track,
    ::-webkit-scrollbar {
        background-color: transparent;
    }
    .content {
        height: 500px;
        display: flex;
        font-size: 12px;
    }
    .content-tree {
        flex: 7;
        height: 100%;
        border-right: 1px solid #f0f0f0;
    }
    .tree-search {
        background-color: #f2f2f2;
        display: flex;
        line-height: 40px;
        .title {
            text-indent: 14px;
            flex: 4;
            font-size: 16px;
        }
        .search-box {
            flex: 6;
            line-height: 40px;
            .el-checkbox {
                margin: 0 10px 0 10px;
            }
            .el-input {
                margin-left: 10px;
                width: 200px;
            }
            .el-button {
                height: 26px;
                min-height: 0;
                margin-left: 10px;
                line-height: 0;
            }
            .addAllBtn {
                margin-left: 10px;
            }
        }
    }
    .tree-org {
        height: 460px;
        display: flex;
    }
    .tree-org-treeBox {
        flex: 4;
        height: 100%;
        border-right: 1px solid #f0f0f0;
        overflow: auto;
        position: relative;
        .el-tree {
            position: absolute;
        }
        .nodeName {
            margin-left: 4px;
            font-size: 14px;
        }
    }
    .el-tree {
        width: 100%;
        margin-top: 10px;
        .el-tree-node__content {
            height: 32px;
        }
    }
    .tree-org-memberBox {
        flex: 6;
        height: 100%;
        padding: 10px 0 10px 0px;
        box-sizing: border-box;
        overflow: auto;
    }
    .tree-org-memberBox-line {
        height: 40px;
        line-height: 40px;
        padding: 0 10px;
        .el-checkbox {
            width: 100%;
            display: flex;
            flex-wrap: nowrap;
            &:focus {
                outline: 0;
            }
        }
        .el-checkbox__input {
            margin-top: 14px;
        }
        .el-checkbox__label {
            font-size: 12px;
            line-height: 40px;
            width: 100%;
            display: flex;
            .tree-org-memberBox-info {
                width: 33.3%;
                overflow: hidden;
            }
            .infoName {
                margin-left: 4px;
            }
        }
        .el-radio {
            width: 100%;
            display: flex;
            flex-wrap: nowrap;
            &:focus {
                outline: 0;
            }
        }
        .el-radio__input {
            margin-top: 14px;
        }
        .el-radio__label {
            font-size: 12px;
            line-height: 40px;
            width: 100%;
            display: flex;
            .tree-org-memberBox-info {
                width: 33.3%;
                overflow: hidden;
            }
            .infoName {
                margin-left: 4px;
            }
        }
    }
    .seletedContent {
        flex: 2;
        height: 100%;
    }
    .seletedContent-num {
        display: flex;
        line-height: 40px;
        background-color: #f2f2f2;
        margin-right: 6px;
        .select-desc {
            flex: 6;
            height: 100%;
            padding-left: 10px;
        }
        .clear {
            margin-right: 10px;
            text-align: right;
        }
    }
    .seletedContent-member {
        height: 440px;
        padding: 10px 0;
        overflow: auto;
        .el-icon-close {
            cursor: pointer;
        }
    }
    .select-line {
        margin: 0 10px;
        width: calc(100%- 20px);
        height: 40px;
        display: flex;
        align-items: center;
        &:focus {
            outline: 0;
        }
        .line-content {
            width: 100%;
            height: 40px;
            display: flex;
        }
        &:hover {
            background-color: #f0f8fa;
        }
    }
    .male {
        display: inline-block;
        color: #67d7d9;
    }
    .female {
        display: inline-block;
        color: #ffa79f;
    }
}
</style>
