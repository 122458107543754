/* eslint-disable indent */
/* eslint-disable complexity */
import { Message, MessageBox } from 'element-ui';
import { ListClass } from './ListClass';
const lang = {
    deleteMsg: '此操作将删除记录, 是否继续?',
    deleteTitle: '提示',
    confirmBtnText: '确定',
    cancelBtnText: '取消',
    saveFormSuccessMsg: '修改成功',
    addFormSuccessMsg: '添加成功',
    operationSuccessMsg: '操作成功',
    selectRowMsg: '至少选择一行记录',
    deleteSuccess: '删除成功'
};
/**
 * @author ex_huangmt
 * 处理列表相关逻辑
 * @param  Options.Api.listApi 列表请求Api
 * @param  Options.foreignModel 列表外键数据
 * @param  Options.Api.deleteApi 删除行
 * @param  Options.Api.batchDeleteApi 批量删除
 * @returns 返回列表相关方法和响应式变量 Object {*}
 */
export class AdvanceListClass extends ListClass {
    /**
     * @author ex_huangmt
     * 处理列表相关逻辑
     * @param  Options.Api.listApi 列表请求Api
     * @param  Options.foreignModel 列表外键数据
     * @param  Options.Api.deleteApi 删除行
     * @param  Options.Api.batchDeleteApi 批量删除
     * @returns 返回列表相关方法和响应式变量 Object {*}
     */
    constructor(Options) {
        super(Options);

        this.deleteApi = Options.Api.deleteApi || null;
        this.batchDeleteApi = Options.Api.batchDeleteApi || null;
    }

    /**
     * 删除动作的前置操作
     * @param row：Object | []  列表需要删除的行
     * @returns row：Object | [] | Boolean:false 处理后的行数据  false:删除终止
     */
    beforeDeleteRow = (row) => {
        return row;
    };
    /**
     **按钮点击删除行
     * @param row：Object | [Object,Object]   行数据，数组为批量
     * @param    Msg.msg 提示消息 默认：提示
     * @param    Msg.title 提示框标题 默认：此操作将删除记录, 是否继续?
     * @param    Msg.confirmBtnText 确定按钮文本 默认：确定
     * @param    Msg.cancelBtnText 取消按钮文本 默认：取消
     */
    deleteRow = (row, Msg = {}) => {
        let rowInfo = JSON.parse(JSON.stringify(row));
        const batchDelete = Array.isArray(rowInfo);
        // 根据 batchDelete判断是否批量删除，去使用各自的api
        const deleteApi = batchDelete ? this.batchDeleteApi : this.deleteApi;
        if (!deleteApi) {
            const errorMsg = batchDelete ? '请先配置 batchDeleteApi' : '请先配置 deleteApi';
            throw new TypeError(errorMsg);
        }

        // 如果是批量删除 则需要至少选择一行
        if (batchDelete && (!this.isCheckedAtLeastOneRow(null))) {
            return false;
        }

        // 如果删除前置操作返回false则不执行删除操作
        rowInfo = this.beforeDeleteRow(rowInfo);
        if ((rowInfo !== false) && (typeof rowInfo !== 'object')) {
            throw new TypeError('beforeDeleteRow 方法必须返回 Object:{} 或  Boolean:false');
        }

        if (rowInfo === false) { return false; }

        MessageBox.confirm(
            Msg.msg || lang.deleteMsg,
            Msg.title || lang.deleteTitle,
            {
                confirmButtonText: Msg.confirmBtnText || lang.confirmBtnText,
                cancelButtonText: Msg.cancelBtnText || lang.cancelBtnText,
                type: 'warning'
            }).then(() => {
                this.tableLoading.value = true;
                deleteApi(rowInfo).then(res => {
                    // console.log('删除成功');
                    const successMsg = Msg.successMsg || lang.deleteSuccess;
                    this.afterDeleteRow(res, successMsg, this.selectedRows.value.length || 1);
                }).finally(res => {
                    this.tableLoading.value = false;
                });
            }).catch(res => res);
    };

    /**
     * 删除行成功后的回调
     * @param res:JSON          删除后接口返回的信息
     * @param successMsg:String   成功提示消息
     * @param nums:Number         删除数量
     */
    afterDeleteRow(res, successMsg, nums) {
        // console.log('删除回调');
        if (+res.code === 0) {
            // console.log('删除回调成功');
            Message.success(successMsg);
            this.getList();
        }
    };

    /**
     * 切换状态前置操作
     * @param row  :Object | Array[Object,Object]
     * @param isBatch   是否批量
     * @returns Object:{ } | Boolean:false   处理后的数据  false则终止
     */
    beforeSwitchStatus = (row) => {
        return row;
    };

    /**
     * 切换状态
     * @param row  :Object | Array[{},{}]       行数据，数组为批量
     * @param statusApi                切换状态的api
     * @param status {                 给接口的目标值
         * value: 1,                   切换到的目标状态值   （必须）
         * confirmMsg: '确认启用?',     确认操作的提示消息   （必须）
         * successMsg: '启用成功！'     操作成功的提示消息   （可选）
     * }
     */
    switchStatus = (row, statusApi, status = {}) => {
        let rowInfo = JSON.parse(JSON.stringify(row));
        rowInfo = this.beforeSwitchStatus(rowInfo);
        if ((rowInfo !== false) && (typeof rowInfo !== 'object')) {
            throw new TypeError('beforeSwitchStatus 方法必须返回 Object:{} 或  Boolean:false');
        }

        if (rowInfo === false) { return false; }
        // 批量操作至少选择一行
        if (Array.isArray(rowInfo) && (!this.isCheckedAtLeastOneRow())) {
            return false;
        }

        MessageBox.confirm(
            status.confirmMsg,
            lang.deleteTitle,
            {
                confirmButtonText: lang.confirmBtnText,
                cancelButtonText: lang.cancelBtnText,
                type: 'warning'
            }).then(() => {
                this.tableLoading.value = true;
                statusApi(rowInfo, status.value).then(res => {
                    const successMsg = status.successMsg || lang.operationSuccessMsg;
                    this.afterSwitchStatus(res, successMsg, this.selectedRows.value.length || 1);
                }).finally(res => {
                    this.tableLoading.value = false;
                });
            }).catch(res => res);
    };
    /**
     * 切换状态后置操作
     * @param res         调用接口后返回的信息
     * @param successMsg  操作成功的消息
     * @param isBatch     是否批量
     * @param nums        操作行的数量
     */
    afterSwitchStatus = (res, successMsg, nums) => {
        if (+res.code === 0) {
            Message.success(successMsg);
            // 取消选中行
            if (this.selectedRows.value.length > 0) {
                this.selectedRows.value = [];
            }
            this.getList();
        }
    }
}
