<template>
    <div>
        <el-input
            v-model="value"
            size="mini"
            :placeholder="config.name"
            :disabled="disabled"
            @keyup.enter.native="show"
            @change="handleChange"
            @clear="clear"
            clearable>
            <template v-slot:append>
                <el-button
                    icon="el-icon-search"
                    class="search-btn"
                    @click="show"
                    :disabled="disabled"
                ></el-button>
            </template>
        </el-input>
        <otp-advance-user ref="userDetailSearch" @confirm="getUserList"></otp-advance-user>
    </div>
</template>

<script>
import _ from 'lodash';
import otpAdvanceUser from '@/components/lots/otpAdvanceUser';

export default {
    name: 'LotsUserSelect',
    components: { otpAdvanceUser },
    props: {
        config: Object,
        label: [String, Number],
        disabled: {
            type: Boolean,
            default: false
        },
        selectList: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    data() {
        return {
            value: ''
        };
    },
    created() {
        this.value = this.label;
    },
    watch: {
        label(val) {
            this.value = val;
        }
    },
    methods: {
        show() {
            const config = this.config;
            this.$refs.userDetailSearch.show(config.isMultCheck, config.filterOrg, config.selectList);
        },
        handleChange() {
            this.clear();
        },
        getUserList(data) {
            const nameList = data.map((item) => {
                return item.userName;
            });
            const codeList = data.map((item) => {
                return item.userCode;
            });
            this.value = nameList.join();
            this.$emit('change', { userName: nameList.join(), userCode: codeList.join(), selectList: data });
        },
        clear() {
            this.$emit('change', { userName: '', userCode: '', selectList: [] });
        }
    }
};
</script>
<style lang="less" scoped>
.search-btn {
    padding: 12px 7px;
}
</style>
