<template>
    <span class="advanced-search-box">
        <el-popover placement="bottom" width="1240" @show="initData" trigger="manual" v-model='showPopover'>
            <template v-slot:reference>
                <el-button class="search-btn" size="mini" @click="showPopover = !showPopover;" icon="iconfont icon-search_senior"></el-button>
            </template>
            <div class="search-set">
                <ul class="search-list-box">
                    <template v-for="item in fields">
                        <li :key="item.name" v-if="item.isFixed || fieldsShowState[item.value]" :class="{ 'is-fussy': item.isFussy }">
                            <span class="label" :title="$translate(item.name)">{{$translate(item.name)}}</span>
                            <div class="form-item">
                                <el-input v-if="item.type === 'input'"
                                    class='text-box'
                                    size="mini"
                                    :disabled="showState || _.get(item, 'disabled', false)"
                                    v-model="searchData[item.value]"
                                    @change="elementChange(item)">
                                </el-input>
                                <inputSelect v-else-if="item.type === 'inputSelect'"
                                    :values="item.value"
                                    :value="searchData[item.value]"
                                    :columns="item.columns"
                                    :prefix="_.get(item, 'prefix', '/api-auth')"
                                    :url="item.url"
                                    :method="_.get(item, 'method', 'get')"
                                    :queryKeys="item.queryKeys"
                                    :queryAdvanceKeys="_.get(item, 'queryAdvanceKeys', '')"
                                    :dataSource="_.get(item, 'dataSource', {})"
                                    :resultKeys="item.resultKeys"
                                    :placeholder="$translate(item.name)"
                                    :disabled="showState || _.get(item, 'disabled', false)"
                                    :clearable="_.get(item, 'clearable', true)"
                                    @change="handleMdInputChange($event, item)"
                                    >
                                </inputSelect>
                                <el-select v-else-if="item.type === 'select'"
                                    class='text-box'
                                    size="mini"
                                    v-model="searchData[item.value]"
                                    :clearable="_.get(item, 'clearable', true)"
                                    :multiple="item.multiple?item.multiple:false"
                                    :filterable="_.get(item, 'filterable', true)"
                                    :disabled="showState || _.get(item, 'disabled', false)"
                                    collapse-tags
                                    @change="elementChange(item)">
                                    <el-option
                                        v-for="(val, index) in item.options"
                                        :key=index
                                        :label="val.label"
                                        :value="val.value">
                                    </el-option>
                                </el-select>
                                <el-date-picker v-else-if="item.type === 'time'"
                                    class='text-box'
                                    v-model="searchData[item.value]"
                                    :clearable="_.get(item, 'clearable', true)"
                                    :disabled="showState || _.get(item, 'disabled', false)"
                                    size="mini"
                                    :format="_.get(item, 'format', 'yyyy-MM-dd HH:mm:ss')"
                                    :type="item.time?'daterange':'datetimerange'"
                                    unlink-panels
                                    range-separator="至"
                                    :start-placeholder="item.startPlaceholder ? $translate(item.startPlaceholder) : $translate('component.E202007300015', { defaultText: '开始日期' })"
                                    :end-placeholder="item.endPlaceholder ? $translate(item.endPlaceholder) : $translate('component.E202007300016', { defaultText: '结束日期' })"
                                    :picker-options="pickerOptions"
                                    :default-time="['00:00:00', '23:59:59']"
                                    @change="elementChange(item)">
                                </el-date-picker>
                                <lots-md-input
                                    v-else-if="item.type === 'advance'"
                                    :label="searchData[item.value]"
                                    :config="item"
                                    :cascadeData="searchData"
                                    :disabled="showState || _.get(item, 'disabled', false)"
                                    @change="handleMdInputChange($event, item)"
                                ></lots-md-input>
                                <otpAdvanceMulti
                                    class="advance-input"
                                    v-else-if="item.type === 'advanceMulti'"
                                    :ref="item.value"
                                    :params="item"
                                    @getAdvanceValue="handleMdInputChange($event, item)"
                                    :disabled="_.get(item, 'disabled', false)"
                                    :mValue="searchData[item.value]"
                                    :formData="searchData">
                                </otpAdvanceMulti>
                                <lots-user-select
                                    v-else-if="item.type === 'advanceUser'"
                                    :label="searchData[item.value]"
                                    :config="item"
                                    :cascadeData="searchData"
                                    :disabled="showState || _.get(item, 'disabled', false)"
                                    @change="handleMdInputChange($event, item)"
                                ></lots-user-select>
                                <addressSelect v-else-if="item.type === 'addressSelect'"
                                    class='text-box'
                                    :disabled="showState || _.get(item, 'disabled', false)"
                                    v-model="searchData[item.value]"
                                    :minLayers="item.minLayers"
                                    :placeholder="$translate(item.name)"
                                    :basicProps="item.config"
                                    @input="elementChange(item)">
                                </addressSelect>
                            </div>
                            <span class="input-append-fussy" v-if="item.isFussy">
                                <el-checkbox v-model="searchData[item.value + 'Fussy']">模糊</el-checkbox>
                            </span>
                        </li>
                    </template>
                </ul>
                <div v-if="!showState" class="btn-box box-border">
                    <div class="text-btn">
                        <el-button class="gray-btn" size="mini" @click="showStateOpen">{{$translate('component.E202007300020', { defaultText: '自定义搜索条件' })}}</el-button>
                    </div>
                    <div class="btn-btn">
                        <el-button class="gray-btn" size="mini" @click="clear">{{$translate('component.E202006240011', { defaultText: '重 置' })}}</el-button>
                        <lots-button type="primary" size="mini" @click="search()">{{$translate('component.E202006240012', { defaultText: '搜 索' })}}</lots-button>
                    </div>
                </div>
                <div v-if="showState">
                    <p class="line"></p>
                    <p>{{$translate('component.E202007300020', { defaultText: '选择添加搜索条件' })}}</p>
                    <ul class="search-total-box">
                        <li v-for="(item,index) in fields" :key="index">
                            <div class="search-total-box_label"
                                :class="{'search-total-box_label-active': fieldsShowState[item.value], 'search-total-box_label-disabled': item.isFixed}"
                                @click="btnChange(item.value, item.isFixed)">
                                {{$translate(item.name)}}
                            </div>
                        </li>
                    </ul>
                    <div class="btn-box">
                        <el-button class="gray-btn" size="mini" @click="cancelSearch">{{$translate('component.E202006152345', { defaultText: '取消' })}}</el-button>
                        <lots-button type="primary" size="mini" @click="saveSearch">{{$translate('component.E202006152346', { defaultText: '保存' })}}</lots-button>
                    </div>
                </div>
            </div>
            <advance-dialog ref="advanceDialog" @confirm="detailSearchSure"></advance-dialog>
            <advance-user ref='advanceUser' @confirm="detailSearchSure"></advance-user>
        </el-popover>
        <!-- 透明背景，防止误点到弹框外其他内容 -->
        <div class="search-set_back" v-show="showPopover" @click.self="showPopover = !showPopover;"></div>
    </span>
</template>
<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import _ from 'lodash';
import { fieldConfig } from '../api';
import advanceDialog from '@/components/lots/otpAdvanceDialog';
import advanceUser from '@/components/lots/otpAdvanceUser';
import addressSelect from '@/components/lots/otpCascader2';
import lotsMdInput from '@/components/lots/lotsMdInput';
import lotsButton from '@/components/lots/lotsButton';
import inputSelect from '@/components/lots/inputSelect/Index';
import lotsUserSelect from '@/components/lots/lotsUserSelect';
import { I18nMixins } from '@/components/mixins/i18n';
import otpAdvanceMulti from '@/components/lots/otpAdvanceMulti/index.vue';
export default {
    mixins: [I18nMixins],
    components: { advanceDialog, addressSelect, advanceUser, lotsMdInput, lotsButton, inputSelect, lotsUserSelect, otpAdvanceMulti },
    props: {
        searchKey: String,
        fields: {
            type: Array,
            default () {
                return [];
            }
        },
        initialVal: {
            type: Object,
            default () {
                return {};
            }
        },
        searchData: {
            type: Object,
            default () {
                return {};
            }
        },
        keyMaps: Object
    },
    data() {
        return {
            id: 'searchBox',
            fieldsShowState: {},
            // searchData: {},
            showState: false,
            showPopover: false,
            pickerOptions: {
                shortcuts: [{
                    text: this.$translate('component.E202007300011', { defaultText: '最近一周' }),
                    onClick(picker) {
                        const DAYS_A_WEEK = 7;
                        const start = dayjs().startOf('day').subtract(DAYS_A_WEEK - 1, 'd').toDate();
                        const end = dayjs().endOf('day').toDate();
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: this.$translate('component.E202007300013', { defaultText: '最近一个月' }),
                    onClick(picker) {
                        const start = dayjs().startOf('day').subtract(1, 'M').toDate();
                        const end = dayjs().endOf('day').toDate();
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            keyMap: {}
        };
    },
    methods: {
        elementChange(column) {
            this.$emit('elementChange', column);
        },
        deepMenu(node) {
            if (!node) {
                return;
            }
            const nodes = [];
            nodes.push(node);
            const childrens = node.subResources;
            if (childrens) {
                for (let i = 0; i < childrens.length; i++) {
                    const hash = window.location.hash.substring(1);
                    if (childrens[i].frontUrl === hash) {
                        this.resourceCode = childrens[i].resourceCode;
                        return childrens[i].resourceCode;
                    } else {
                        this.deepMenu(childrens[i]);
                    }
                }
            }
        },
        // 切换自定义搜索条件按钮，isFixed为默认查询项，不可操作
        btnChange(field, isFixed) {
            if (isFixed) return;
            this.$set(this.fieldsShowState, field, !this.fieldsShowState[field]);
        },
        cancelSearch() {
            this.getSetList();
            this.showState = false;
        },
        showStateOpen() {
            this.showState = true;
        },
        saveSearch() {
            this.showState = false;
            const configName = [];
            const configNameCn = [];
            this.fields.forEach(o => {
                if (this.fieldsShowState[o.value]) {
                    configName.push(o.value);
                    configNameCn.push(o.name);
                }
            });
            const params = {
                id: this.id,
                configName: configName.join(','),
                configNameCn: configNameCn.join(','),
                subjectType: this.searchKey,
                moduleCode: this.resourceCode
            };
            fieldConfig.set(params).then(() => {
            }).catch(() => {});
        },
        clear() {
            for (const key in this.searchData) {
                if (key.includes('Fussy')) {
                    this.searchData[key] = false;
                } else {
                    this.searchData[key] = null;
                }
            }
        },
        search() {
            const searchData = _.cloneDeep(this.searchData);
            this.$emit('search', searchData);
            this.showPopover = false;
        },
        // 高级弹框
        detailSearch(current) {
            // 高级搜索级联操作，current.advanceCascade为需要级联的参数的key的集合，例如advanceCascade: key1,key2,key3
            const advanceCascade = {};
            if (_.isString(current.advanceCascade)) {
                let advanceArray = [];
                advanceArray = current.advanceCascade.split(',');
                advanceArray.map((item) => {
                    let { 0: sourceProp, 1: destProp } = item.split('#');
                    destProp = destProp || sourceProp;
                    advanceCascade[destProp] = this.searchData[sourceProp];
                });
            }
            this.$refs.advanceDialog.show(true, current, advanceCascade);
        },
        detailSearchSure(data, column = {}) {
            // 将返回数据添加到map里，用于高级搜索清空时，顺带清空其他关联属性
            const keyArr = Object.keys(data);
            if (keyArr.length > 0) {
                keyArr.forEach(key => {
                    this.keyMap[key] = keyArr;
                });
            }
            // this.searchData = { ...this.searchData, ...data };
            Object.keys(data).forEach(key => {
                this.searchData[key] = data[key];
            });
            this.elementChange(column);
        },
        // 获取搜索项配置
        async getSetList() {
            const menu = { subResources: _.cloneDeep(this.menus) };
            this.deepMenu(menu);
            let checkedList = null;
            const fieldsShowState = {};
            const params = {
                id: this.id,
                subjectType: this.searchKey,
                moduleCode: this.resourceCode
            };
            try {
                const res = await fieldConfig.get(params);
                if (res.data && res.data.configName) {
                    checkedList = res.data.configName;
                }
            } catch (error) {
                return;
            }
            this.fields.forEach(o => {
                fieldsShowState[o.value] = !checkedList ? true : checkedList.includes(o.value);
            });
            this.fieldsShowState = fieldsShowState;
        },
        // 页面初始化
        initData() {
            // 获取搜索项配置
            this.getSetList();
            this.showState = false;
        },
        handleMdInputChange(data, column) {
            // this.searchData = { ...this.searchData, ...data };
            if (column.type === 'advanceMulti') {
                const params = {};
                if (data && Array.isArray(data)) {
                    if (data.length) {
                        const keyArr = Object.keys(data[0]);
                        keyArr.forEach(key => {
                            if (!params[key + 's']) { params[key + 's'] = []; }
                            data.forEach(item => {
                                params[key + 's'].push(item[key]);
                            });
                            params[key + 's'] = params[key + 's'].join();
                        });
                    } else {
                        column.cbParams.forEach(keySub => {
                            const arr = keySub.split('#');
                            params[arr[arr.length - 1] + 's'] = null;
                        });
                    }
                }
                Object.assign(this.searchData, params);
            } else {
                Object.keys(data).forEach(key => {
                    this.searchData[key] = data[key];
                });
            }
            this.elementChange(column);
        }
    },
    watch: {
        // initialVal(val) {
        //     this.searchData = { ...this.searchData, ...val };
        // },
        keyMaps: {
            handler(val) {
                this.keyMap = { ...this.keyMap, ...val };
            },
            deep: true
        }
    },
    computed: {
        ...mapGetters(['currentTenant', 'menus']),
        _() {
            return _;
        }
    }
};

</script>
<style lang="less" scoped>
</style>
<style lang="less">
.advanced-search-box{
    .search-btn {
        background: rgba(66,133,245,0.10);
        border-color: transparent;
    }
    .el-select {
        width: 100%;
    }
    .iconsearch_senior {
        color: #4285F5;
    }
    .search-set_back {
        position: fixed;
        z-index: 1000;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: auto;
        margin: 0;
        // opacity: 0.5;
        // background: #000000;
    }
    .advance-input {
        .el-input__inner {
            padding-right: 20px;
        }
        .el-input-group__append {
            text-align: center;
            padding: 0 14px;
            .el-button {
                padding: 8px;
            }
        }
    }
}
.search-set {
    .el-input__inner {
        height: 28px !important;
        padding: 3px 10px;
    }
    ul {
        display: flex;
        flex-wrap: wrap;
    }

    .search-list-box {
        margin-top: 10px;
        max-height: 42vh;
        overflow-y: scroll;
        padding: 0;

        >li {
            width: 400px;
            margin-bottom: 10px;
            display: flex;

            >.label {
                font-size: 12px;
                display: inline-block;
                height: 28px;
                line-height: 28px;
                width: 100px;
                padding: 0 10px 0 0;
                text-align: right;
                overflow: hidden;
                text-overflow:ellipsis;
                white-space: nowrap;
            }

            >.form-item {
                width: 320px;
                >div{
                    width: 100%;
                    vertical-align: middle;
                }
            }

            .select-btn {
                color: #eee !important;
            }
            &.is-fussy {
                .form-item {
                    width: 260px;
                }
                .input-append-fussy {
                    width: 48px;
                    margin-top: 4px;
                    margin-left: 6px;
                    .el-checkbox__label {
                        padding-left: 4px;
                    }
                }
            }
        }
    }

    .line {
        height: 1px;
        width: 100%;
        background-color: #F2F2F2;
    }

    .search-total-box {
        max-height: 120px;
        overflow: auto;
        list-style: none;
        padding: 0;

        li {
            width: 144px;
            padding: 0 10px 10px 0;
        }
        .search-total-box_label {
            padding: 5px 10px;
            font-size: 12px;
            border-radius: 4px;
            border: 1px solid #cecece;
            text-align: center;
            cursor: pointer;
        }
        .search-total-box_label-active {
            color: #409EFF;
            border-color: #409EFF;
        }
        .search-total-box_label-disabled {
            cursor: not-allowed;
        }

    }

    .btn-box {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        .text-btn{
            width: 40%;
        }
        .btn-btn{
            width: 60%;
            display: flex;
            justify-content: flex-end;
        }
        .gray-btn {
            background: #f5f5f5;
            color: #666;
            border: none;
            &:hover {
                color: #4285f5;
            }
        }
    }
    .box-border {
        border-top: 1px solid #f0f0f0;
        padding-top: 10px;
    }

    .text-box{
    position: relative;
    font-size: 12px;
    width: 100%;
    }
    .v-modal{
        z-index: 2000!important;
    }
}
</style>
